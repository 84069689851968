import React, { useEffect, useState } from "react";
import userDefault from "src/modules/common/components/images/user_default.png";
import { DESCRIPCIONES_NINEBOX } from "../helpers/constants";

const getBoxColor = (index) => {
  if (index === 1 || index === 2 || index === 5) {
    return { bg: "rgb(232, 247, 229)", border: "rgb(30, 176, 0)" };
  } else if (index === 0 || index === 4 || index === 8) {
    return { bg: "rgb(255, 247, 230)", border: "rgb(240, 201, 120)" };
  } else if (index === 3 || index === 6 || index === 7) {
    return { bg: "rgb(252, 235, 235)", border: "rgb(246, 146, 105)" };
  }
  return { bg: "transparent", border: "transparent" };
};

const NineBox = ({ nineboxData, puntaje = null, mostrarRangosPuntajes = false, extraClassName = "" }) => {
  // Reorganizar nineboxData en el orden deseado
  const [reorderedData, setReorderedData] = useState(
    nineboxData.length === 9
      ? [
        nineboxData[5],
        nineboxData[2],
        nineboxData[0],
        nineboxData[7],
        nineboxData[4],
        nineboxData[1],
        nineboxData[8],
        nineboxData[6],
        nineboxData[3],
      ]
      : []
  );

  useEffect(() => {
    if (puntaje !== null) {
      const updatedData = reorderedData.map(item => {
        if (puntaje >= item.puntaje_desde && puntaje <= item.puntaje_hasta) {
          return { ...item, puntajeAsignado: puntaje };
        }
        return { ...item, puntajeAsignado: null };
      });
      setReorderedData(updatedData);
    }
  }, [puntaje]);

  return (
    <>
      <div className="flex items-center space-x-4 pl-8 border-b py-4">
        <div className="flex items-center space-x-2">
          <span
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: "#ADD8E6" }} // Color azul claro
          ></span>
          <span className="text-sm font-bold">Vertical: Desempeño</span>
        </div>
        <div className="flex items-center space-x-2">
          <span
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: "#90EE90" }} // Color verde claro
          ></span>
          <span className="text-sm font-bold">Horizontal: Potencial</span>
        </div>
      </div>
      <div className="relative">
        {/* Ejes y flechas */}
        <div className="absolute bottom-4 left-0 w-full h-full pointer-events-none">
          {/* Flecha vertical */}
          <div className="absolute top-0 h-full flex flex-col items-center justify-between">
            <h1 className="text-xs">Alto</h1>
            <h1 className="text-xs">Medio</h1>
            <h1 className="text-xs">Bajo</h1>
          </div>
          <div className="absolute left-10 top-0 h-full flex flex-col items-center">
            <div className="flex-grow border-l-4 border-[#ADD8E6]"></div>
          </div>
        </div>

        {/* Caja del NineBox */}
        <div className={`grid grid-cols-3 gap-4 pl-14 ${extraClassName}`}>
          {reorderedData.map((box, index) => (
            <div
              key={box.id}
              className="flex flex-col justify-center items-center rounded-lg p-4 text-center h-32 border-l-4"
              style={{
                backgroundColor: getBoxColor(index).bg,
                borderColor: getBoxColor(index).border,
              }}
            >
              <h3 className="text-lg font-bold">{box.nombre}</h3>
              {mostrarRangosPuntajes && (
                <p className="text-sm ">
                  Puntaje: {box.puntaje_desde} - {box.puntaje_hasta}%
                </p>
              )}
              {/* Esta parte es para informe individual cuando se recibe colaborador. */}
              {box.colaborador && (
                <div className="flex flex-row justify-center items-center gap-4 mt-2 relative group">
                  <img
                    className="w-10 h-10 rounded-md"
                    src={box.colaborador.foto_empleado || userDefault}
                    title={box.colaborador.nombre_completo}
                    alt="Foto de perfil"
                  />
                  <p className="rounded-md p-2 bg-black text-white text-sm">
                    Se encuentra aquí
                  </p>
                  <div className="absolute bottom-full w-[600px] mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-gray-800 text-white text-xs rounded-md px-2 py-1 shadow-lg">
                    {DESCRIPCIONES_NINEBOX[index]}
                  </div>
                </div>
              )}
              {!box.colaborador && (box.puntajeAsignado === 0 || !!box.puntajeAsignado) && (
                <div className="flex justify-center items-center mt-2">
                  <p className="rounded-md p-2 bg-black text-white text-sm">
                    Se encuentra aquí
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Flecha horizontal */}
        <div>
          <div className="border-2 ml-10 border-[#90EE90]"></div>
          <div className="ml-10 flex justify-between">
            <h1 className="text-xs">Bajo</h1>
            <h1 className="text-xs">Medio</h1>
            <h1 className="text-xs">Alto</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default NineBox;
